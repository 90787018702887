/**
 * Class reprsenting a CVX ClaimsPrincipal object.
 *
 * @class
 * @implements {ICvxClaimsPrincipal}
 */
var CvxClaimsPrincipal = /** @class */ (function () {
    function CvxClaimsPrincipal(jsonInput) {
        if (jsonInput === void 0) { jsonInput = null; }
        this._isUser = false;
        if (jsonInput === null) {
            return;
        }
        var jsonInputType = typeof (jsonInput);
        var jsonObj;
        if (jsonInputType === "string") {
            jsonObj = JSON.parse(jsonInput);
        }
        else if (jsonInputType === "object") {
            jsonObj = jsonInput;
        }
        else {
            return;
        }
        for (var prop in jsonObj) {
            this["".concat(prop)] = jsonObj[prop];
        }
    }
    Object.defineProperty(CvxClaimsPrincipal.prototype, "objectId", {
        get: function () { return this._objectId; },
        set: function (value) { this._objectId = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "email", {
        get: function () { return this._email; },
        set: function (value) { this._email = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "userName", {
        get: function () { return this._userName; },
        set: function (value) { this._userName = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "name", {
        get: function () { return this._name; },
        set: function (value) { this._name = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "audience", {
        get: function () { return this._audience; },
        set: function (value) { this._audience = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "roles", {
        get: function () { return this._roles; },
        set: function (value) { this._roles = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "issuer", {
        get: function () { return this._issuer; },
        set: function (value) { this._issuer = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "tenantId", {
        get: function () { return this._tenantId; },
        set: function (value) { this._tenantId = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "tokenVersion", {
        get: function () { return this._tokenVersion; },
        set: function (value) { this._tokenVersion = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "provisioningId", {
        get: function () { return this._provisioningId; },
        set: function (value) { this._provisioningId = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "logOnName", {
        get: function () { return this._logOnName; },
        set: function (value) { this._logOnName = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "userType", {
        get: function () { return this._userType; },
        set: function (value) { this._userType = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "personRelationshipType", {
        get: function () { return this._personRelationshipType; },
        set: function (value) { this._personRelationshipType = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "isUser", {
        get: function () { return this._isUser; },
        set: function (value) { this._isUser = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "msalAccountId", {
        get: function () { return this._msalAccountId; },
        set: function (value) { this._msalAccountId = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "jobTitle", {
        get: function () { return this._jobTitle; },
        set: function (value) { this._jobTitle = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "phoneNumber", {
        get: function () { return this._phoneNumber; },
        set: function (value) { this._phoneNumber = value; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CvxClaimsPrincipal.prototype, "office", {
        get: function () { return this._office; },
        set: function (value) { this._office = value; },
        enumerable: false,
        configurable: true
    });
    return CvxClaimsPrincipal;
}());
export { CvxClaimsPrincipal };
