/**
 * @member MS_GRAPH_ENDPOINT The base MS Graph endpoint used in this library.
 * @member MS_GRAPH_DEFAULT_ME_QUERY The default starting query for the ME endpoint.
 * @member MS_GRAPH_CHECK_MEMBER_GROUPS_ENDPOINT The default group membership starting path of the ME endpoint.
 * @member MS_GRAPH_GROUPS_ENDPOINT The default starting path for the Groups endpoint.
 * @member LOCAL_STORAGE_CVX_CLAIMS_PRINCIPAL_KEY The LocalStorage cache key used for the current CvxClaimsPrincipal.
 * @member MS_GRAPH_DEFAULT_ATTRIBUTES The default MS Graph Attributes mapping
 */
var Constants = /** @class */ (function () {
    function Constants() {
    }
    Constants.MS_GRAPH_ENDPOINT = "https://graph.microsoft.com/";
    Constants.MS_GRAPH_DEFAULT_ME_QUERY = "v1.0/me?$select=";
    Constants.MS_GRAPH_CHECK_MEMBER_GROUPS_ENDPOINT = "v1.0/me/checkMemberGroups";
    Constants.MS_GRAPH_GET_MEMBER_ROLES_ENDPOINT = "v1.0/me/appRoleAssignments";
    Constants.MS_GRAPH_GET_MEMBER_GROUPS_ENDPOINT = "v1.0/me/memberOf";
    Constants.MS_GRAPH_GROUPS_ENDPOINT = "v1.0/groups";
    Constants.LOCAL_STORAGE_CVX_CLAIMS_PRINCIPAL_KEY = "cachedCvxClaimsPrincipal";
    Constants.MS_GRAPH_ATTRIBUTES_MAP = new Map([
        ["id", "id"],
        ["jobTitle", "jobTitle"],
        ["officeLocation", "officeLocation"],
        ["userPrincipalName", "userPrincipalName"],
        ["mobilePhone", "mobilePhone"],
        ["onPremisesSamAccountName ", "onPremisesSamAccountName"],
        ["employeeType", "extension_39c7d3e68666465dab296ee0fc538118_cvx_PersonRelationshipType"],
        ["provisioningId", "extension_39c7d3e68666465dab296ee0fc538118_cvx_ProvisioningID"],
        ["userType", "userType"],
        ["accountEnabled", "accountEnabled"],
        ["mail", "mail"]
    ]);
    return Constants;
}());
export { Constants };
