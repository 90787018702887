import { __awaiter, __generator } from "tslib";
import { Constants } from "../models/Constants";
/**
 * Class representing an MS Graph Client
 *
 * @class
 * @implements {IMsGraphClient}
 */
var MsGraphClient = /** @class */ (function () {
    function MsGraphClient() {
    }
    MsGraphClient.prototype.getUserProperties = function (token, properties) {
        return __awaiter(this, void 0, void 0, function () {
            var props;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        props = (((properties === null || properties === void 0 ? void 0 : properties.length) || 0) === 0)
                            ? Array.from(Constants.MS_GRAPH_ATTRIBUTES_MAP.values())
                            : properties.map(function (property) {
                                return Constants.MS_GRAPH_ATTRIBUTES_MAP.get(property) || property;
                            });
                        return [4 /*yield*/, this.callMSGraph(token, "".concat(Constants.MS_GRAPH_DEFAULT_ME_QUERY).concat(props.join(",")))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MsGraphClient.prototype.checkMemberGroups = function (token, groups) {
        return __awaiter(this, void 0, void 0, function () {
            var groupIds, _i, groups_1, group, found;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        groupIds = [];
                        _i = 0, groups_1 = groups;
                        _a.label = 1;
                    case 1:
                        if (!(_i < groups_1.length)) return [3 /*break*/, 4];
                        group = groups_1[_i];
                        return [4 /*yield*/, this.getMemberGroupIdbyName(token, group)];
                    case 2:
                        found = _a.sent();
                        if (found)
                            groupIds.push(found);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        if (groupIds.length === 0) {
                            console.log("No group IDs were found for the provided group names.");
                            return [2 /*return*/, []];
                        }
                        return [4 /*yield*/, this.callMSGraph(token, Constants.MS_GRAPH_CHECK_MEMBER_GROUPS_ENDPOINT, JSON.stringify({ groupIds: groupIds }))];
                    case 5: return [2 /*return*/, (_a.sent()).value];
                }
            });
        });
    };
    MsGraphClient.prototype.getMemberGroupIdbyName = function (token, groupName) {
        return __awaiter(this, void 0, void 0, function () {
            var headerDict, groups, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headerDict = {
                            'Authorization': "Bearer ".concat(token),
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'ConsistencyLevel': 'eventual'
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        groupName = encodeURIComponent(groupName);
                        if (groupName.includes('%22')) {
                            groupName = groupName.replace(/%22/g, '\\"');
                        }
                        return [4 /*yield*/, this.callMSGraph(token, "".concat(Constants.MS_GRAPH_GET_MEMBER_GROUPS_ENDPOINT, "/microsoft.graph.group?$search=\"displayName:").concat(groupName, "\"&$select=id"), null, headerDict)];
                    case 2:
                        groups = _a.sent();
                        if (groups.value.length > 0) {
                            return [2 /*return*/, groups.value[0].id];
                        }
                        return [2 /*return*/, null];
                    case 3:
                        error_1 = _a.sent();
                        throw ("Group not found or could not be fetched: ".concat(error_1));
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MsGraphClient.prototype.getGroupIdbyName = function (token, groupName) {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.callMSGraph(token, "".concat(Constants.MS_GRAPH_GROUPS_ENDPOINT, "?$filter=displayName eq '").concat(groupName.replace(/'/g, "%27"), "'&$select=id"))];
                    case 1: return [2 /*return*/, (_a.sent()).value[0].id];
                    case 2:
                        error_2 = _a.sent();
                        throw ("Group not found or could not be fetched: ".concat(error_2));
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MsGraphClient.prototype.getRoles = function (token, count) {
        return __awaiter(this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.callMSGraph(token, "".concat(Constants.MS_GRAPH_GET_MEMBER_ROLES_ENDPOINT, "?$top=").concat(count))];
                    case 1: return [2 /*return*/, (_a.sent())];
                    case 2:
                        error_3 = _a.sent();
                        throw ("Roles could not be fetched: ".concat(error_3));
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MsGraphClient.prototype.getRolesByResourceId = function (token, resourceId) {
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.callMSGraph(token, "".concat(Constants.MS_GRAPH_GET_MEMBER_ROLES_ENDPOINT, "?$filter=resourceId eq ").concat(resourceId.replace(/'/g, "%27")))];
                    case 1: return [2 /*return*/, (_a.sent())];
                    case 2:
                        error_4 = _a.sent();
                        throw ("Role not found or could not be fetched: ".concat(error_4));
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MsGraphClient.prototype.getRolesByResourceIds = function (token, resourceIds) {
        return __awaiter(this, void 0, void 0, function () {
            var resourceQuery, i, query, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        resourceQuery = '';
                        for (i = 0; i < resourceIds.length - 1; i++) {
                            resourceQuery += "resourceId eq ".concat(resourceIds[i], " OR ").replace(/'/g, "%27");
                        }
                        resourceQuery += "resourceId eq ".concat(resourceIds[resourceIds.length - 1]).replace(/'/g, "%27");
                        query = '?$filter=' + "".concat(resourceQuery);
                        return [4 /*yield*/, this.callMSGraph(token, "".concat(Constants.MS_GRAPH_GET_MEMBER_ROLES_ENDPOINT).concat(query))];
                    case 1: return [2 /*return*/, (_a.sent())];
                    case 2:
                        error_5 = _a.sent();
                        throw ("Roles not found or could not be fetched: ".concat(error_5));
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MsGraphClient.prototype.callMSGraph = function (token, query, requestBody, customHeaders) {
        if (requestBody === void 0) { requestBody = null; }
        if (customHeaders === void 0) { customHeaders = null; }
        return __awaiter(this, void 0, void 0, function () {
            var head, options, error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        head = null;
                        if (customHeaders) {
                            head = customHeaders;
                        }
                        else {
                            head = {
                                'Authorization': "Bearer ".concat(token),
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            };
                        }
                        options = {
                            method: requestBody == null ? "GET" : "POST",
                            headers: new Headers(head),
                            body: requestBody
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetch(encodeURI("".concat(Constants.MS_GRAPH_ENDPOINT).concat(query)), options)];
                    case 2: return [2 /*return*/, (_a.sent()).json()];
                    case 3:
                        error_6 = _a.sent();
                        throw ("Call to graph failed: ".concat(error_6));
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return MsGraphClient;
}());
export { MsGraphClient };
