import { __assign, __awaiter, __generator } from "tslib";
import { PublicClientApplication, BrowserCacheLocation } from "@azure/msal-browser";
import { CvxClaimsPrincipal } from "./models/CvxClaimsPrincipal";
import { MsGraphClient } from "./core/MsGraphClient";
import { Constants } from "./models/Constants";
var CalClient = /** @class */ (function () {
    function CalClient(calConfig) {
        this._msGraphClient = new MsGraphClient();
        this._config = calConfig;
        this.validateConfig();
        this.setupPublicClientApplication();
    }
    Object.defineProperty(CalClient.prototype, "cvxClaimsPrincipal", {
        get: function () {
            return this._cvxClaimsPrincipal;
        },
        enumerable: false,
        configurable: true
    });
    CalClient.prototype.isUserSignedIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var claims, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this._account = this.getAccount();
                        return [4 /*yield*/, this.getCvxClaimsPrincipalFromLocalStorageOrPopulate(this._account)];
                    case 1:
                        claims = _a.sent();
                        return [2 /*return*/, this._account !== null && claims !== undefined];
                    case 2:
                        error_1 = _a.sent();
                        console.log("Error checking account to see if logged in: ".concat(error_1));
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CalClient.prototype.userInitiatedSignIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loggedIn, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isUserSignedIn()];
                    case 1:
                        loggedIn = _a.sent();
                        if (!!loggedIn) return [3 /*break*/, 8];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 7]);
                        return [4 /*yield*/, this.signInUser()];
                    case 3:
                        _a.sent();
                        if (!this.shouldPopupForLogin()) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getCvxClaimsPrincipalFromLocalStorageOrPopulate(this.getAccount())];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/, this._cvxClaimsPrincipal];
                    case 6:
                        error_2 = _a.sent();
                        throw ("Was not able to login the user successfully: ".concat(error_2));
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        console.log("User is already signed in.");
                        return [4 /*yield*/, this.getCvxClaimsPrincipalFromLocalStorageOrPopulate(this._account)];
                    case 9:
                        _a.sent();
                        return [2 /*return*/, this._cvxClaimsPrincipal];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    CalClient.prototype.userInitiatedSignOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            var currentAccount, cacheStorage;
            return __generator(this, function (_a) {
                currentAccount = this.getAccount();
                if (currentAccount == null) {
                    console.log("No user was signed in, skipping.");
                    return [2 /*return*/, true];
                }
                try {
                    cacheStorage = this.getCacheStorage();
                    cacheStorage.removeItem(Constants.LOCAL_STORAGE_CVX_CLAIMS_PRINCIPAL_KEY);
                    this._clientApplication.logoutRedirect({
                        account: currentAccount
                    });
                    return [2 /*return*/, true];
                }
                catch (error) {
                    console.log("An error has occured during user initiated sign out: ".concat(error));
                    return [2 /*return*/, false];
                }
                return [2 /*return*/];
            });
        });
    };
    CalClient.prototype.isInGroup = function (groupCheck) {
        return __awaiter(this, void 0, void 0, function () {
            var groupObj, aadToken, groupsUserIsIn;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (typeof (groupCheck) === "string") {
                            groupObj = [groupCheck];
                        }
                        else if (groupCheck instanceof Array) {
                            groupObj = groupCheck;
                        }
                        else {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.getAADToken(this._config.graphScopes)];
                    case 1:
                        aadToken = _a.sent();
                        if (!(aadToken == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getAADToken(this._config.graphScopes)];
                    case 2:
                        aadToken = (_a.sent());
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._msGraphClient.checkMemberGroups(aadToken, groupObj)];
                    case 4:
                        groupsUserIsIn = _a.sent();
                        return [2 /*return*/, groupsUserIsIn.length > 0];
                }
            });
        });
    };
    CalClient.prototype.getRoles = function (count) {
        if (count === void 0) { count = 100; }
        return __awaiter(this, void 0, void 0, function () {
            var aadToken, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAADToken(this._config.graphScopes)];
                    case 1:
                        aadToken = _a.sent();
                        if (!(aadToken == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getAADToken(this._config.graphScopes)];
                    case 2:
                        aadToken = (_a.sent());
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._msGraphClient.getRoles(aadToken, count)];
                    case 4:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    CalClient.prototype.getRolesByResourceIds = function (resourceIds) {
        return __awaiter(this, void 0, void 0, function () {
            var aadToken, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAADToken(this._config.graphScopes)];
                    case 1:
                        aadToken = _a.sent();
                        if (!(aadToken == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getAADToken(this._config.graphScopes)];
                    case 2:
                        aadToken = (_a.sent());
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._msGraphClient.getRolesByResourceIds(aadToken, resourceIds)];
                    case 4:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    CalClient.prototype.getRolesByResourceId = function (resourceId) {
        return __awaiter(this, void 0, void 0, function () {
            var aadToken, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAADToken(this._config.graphScopes)];
                    case 1:
                        aadToken = _a.sent();
                        if (!(aadToken == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getAADToken(this._config.graphScopes)];
                    case 2:
                        aadToken = (_a.sent());
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._msGraphClient.getRolesByResourceId(aadToken, resourceId)];
                    case 4:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    CalClient.prototype.getUserPropertiesFromMsGraph = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            var aadTokenResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAADToken(this._config.oidcScopes, true)];
                    case 1:
                        aadTokenResponse = _a.sent();
                        if (!(aadTokenResponse == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getAADToken(this._config.oidcScopes, true)];
                    case 2:
                        aadTokenResponse = (_a.sent());
                        _a.label = 3;
                    case 3: return [2 /*return*/, this._msGraphClient.getUserProperties(aadTokenResponse.accessToken, properties)];
                }
            });
        });
    };
    CalClient.prototype.getAADToken = function (oidcScopes, fullAuthResponse) {
        if (oidcScopes === void 0) { oidcScopes = this._config.oidcScopes; }
        if (fullAuthResponse === void 0) { fullAuthResponse = false; }
        return __awaiter(this, void 0, void 0, function () {
            var authResponse, error_3, popupAuthResponse, innerError_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.getAccount()) {
                            throw ("Account does not exist yet – initialize the UserAgent.");
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 8]);
                        return [4 /*yield*/, this._clientApplication.acquireTokenSilent({
                                scopes: oidcScopes,
                                account: this._account,
                                forceRefresh: false
                            })];
                    case 2:
                        authResponse = _a.sent();
                        if (fullAuthResponse) {
                            return [2 /*return*/, authResponse];
                        }
                        return [2 /*return*/, authResponse.accessToken];
                    case 3:
                        error_3 = _a.sent();
                        console.log("Could not get the access token from AAD silently, defaulting to get token from AAD popup: ".concat(error_3));
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this._clientApplication.acquireTokenPopup({
                                scopes: oidcScopes,
                                account: this._account
                            })];
                    case 5:
                        popupAuthResponse = _a.sent();
                        if (fullAuthResponse) {
                            return [2 /*return*/, popupAuthResponse];
                        }
                        return [2 /*return*/, popupAuthResponse.accessToken];
                    case 6:
                        innerError_1 = _a.sent();
                        console.log("Could not get the access token from AAD popup, defaulting to get token from AAD redirect: ".concat(innerError_1));
                        this._clientApplication.acquireTokenRedirect({
                            scopes: oidcScopes,
                            account: this._account,
                            redirectStartPage: window.location.href
                        });
                        return [2 /*return*/, null];
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    CalClient.prototype.getAccount = function () {
        var currentAccounts = this._clientApplication.getAllAccounts();
        if (currentAccounts === null) {
            return null;
        }
        if (currentAccounts.length > 1) {
            // Add choose account code here, currently we only support one signed in user.
            return currentAccounts[0];
        }
        else if (currentAccounts.length === 1) {
            return currentAccounts[0];
        }
        return null;
    };
    CalClient.prototype.getIdTokenClaims = function () {
        var currentAccounts = this._clientApplication.getAllAccounts();
        if (currentAccounts === null) {
            return null;
        }
        if (currentAccounts.length > 1) {
            // Add choose account code here, currently we only support one signed in user.
            return currentAccounts[0].idTokenClaims;
        }
        else if (currentAccounts.length === 1) {
            return currentAccounts[0].idTokenClaims;
        }
        return null;
    };
    CalClient.prototype.getClaims = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.isUserSignedIn().then(function (value) {
                        if (value) {
                            return _this.userInitiatedSignIn().then(function (data) {
                                return data;
                            });
                        }
                        else {
                            console.log("User not yet signed in, could not get claims.");
                            return null;
                        }
                    })];
            });
        });
    };
    CalClient.prototype.getAccessTokenFromCache = function () {
        if (this._config.cacheLocation == BrowserCacheLocation.MemoryStorage) {
            throw "getAccessTokenFromCache is not supported for ".concat(BrowserCacheLocation.MemoryStorage, " cache storage location");
        }
        var tokenKey;
        var cache = this.getCacheStorage();
        for (var i = 0; i < cache.length; i++) {
            if (cache.key(i).includes("login.windows.net-accesstoken")) {
                tokenKey = cache.getItem(cache.key(i));
                break;
            }
        }
        return tokenKey;
    };
    CalClient.prototype.setupPublicClientApplication = function () {
        var _this = this;
        var msalConfig = {
            auth: {
                clientId: this._config.clientId,
                authority: "".concat(this._config.instance).concat(this._config.tenantId),
                redirectUri: this._config.redirectUri
            },
            cache: {
                storeAuthStateInCookie: this._config.cacheLocation == BrowserCacheLocation.MemoryStorage,
                cacheLocation: this._config.cacheLocation
            }
        };
        this._clientApplication = new PublicClientApplication(msalConfig);
        if (!this._clientApplication) {
            throw ("Could not create client application from MSAL library");
        }
        // This handle redirect is required to handle redirect login types
        this._clientApplication.handleRedirectPromise().then(function (tokenResponse) {
            // Check if the tokenResponse is null
            // If the tokenResponse !== null, then you are coming back from a successful authentication redirect. 
            // If the tokenResponse === null, you are not coming back from an auth redirect.
            _this.handleResponse(tokenResponse);
        }).catch(function (error) {
            // handle error, either in the library or coming back from the server
            console.log("Error detected while redirecting: ".concat(error));
        });
    };
    CalClient.prototype.handleResponse = function (response) {
        if (response !== null) {
            this.getCvxClaimsPrincipalFromLocalStorageOrPopulate(response.account).then(function () {
                location.reload();
            }).catch(function (error) { return console.log("Could not populate CvxClaimsPrincipal on redirect: ".concat(error)); });
            this._account = response.account;
        }
        else {
            var currentAccounts = this._clientApplication.getAllAccounts();
            if (!currentAccounts || currentAccounts.length < 1) {
                //No
                this.checkAccountsForSignIn(currentAccounts);
            }
            else if (currentAccounts.length === 1) {
                this._clientApplication.setActiveAccount(currentAccounts[0]);
                this.getCvxClaimsPrincipalFromLocalStorageOrPopulate(currentAccounts[0])
                    .catch(function (error) { return console.log("Something went wrong with retrieving the CvxClaimsPrincipal: ".concat(error)); });
                this._account = currentAccounts[0];
            }
            else if (currentAccounts.length > 1) {
                // Add choose account code here, currently we will only support 1 logged in user
            }
        }
    };
    CalClient.prototype.checkAccountsForSignIn = function (currentAccounts) {
        var _this = this;
        this.isUserSignedIn().then(function (userIsSignedIn) {
            if (userIsSignedIn) {
                _this.getCvxClaimsPrincipalFromLocalStorageOrPopulate(currentAccounts[0])
                    .catch(function (error) { return console.log("Something went wrong with retrieving the CvxClaimsPrincipal: ".concat(error)); });
            }
            else if (_this._config.autoSignIn) {
                _this.signInUser().then(function () {
                    if (_this.shouldPopupForLogin()) {
                        _this.getCvxClaimsPrincipalFromLocalStorageOrPopulate(_this._account).then(function () {
                            // TODO: support for memoryStorage... how to notify app without refreshing the page (as signin would start again)
                            window.location.reload(); //when autosign in is turned on with popup we need to reload page to see changes
                        }).catch(function (error) { return console.log("Something went wrong with retrieving the CvxClaimsPrincipal: ".concat(error)); });
                    }
                }).catch(function (error) { return console.log("Could not sign in user: ".concat(error)); });
            }
        });
    };
    CalClient.prototype.getCvxClaimsPrincipalFromLocalStorageOrPopulate = function (account) {
        return __awaiter(this, void 0, void 0, function () {
            var rawCvxPrincipalObject, cacheStorage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._cvxClaimsPrincipal != null) {
                            return [2 /*return*/, this._cvxClaimsPrincipal];
                        }
                        rawCvxPrincipalObject = null;
                        cacheStorage = this.getCacheStorage();
                        rawCvxPrincipalObject = cacheStorage.getItem(Constants.LOCAL_STORAGE_CVX_CLAIMS_PRINCIPAL_KEY);
                        if (rawCvxPrincipalObject != null) {
                            this._cvxClaimsPrincipal = new CvxClaimsPrincipal(rawCvxPrincipalObject);
                            return [2 /*return*/, this._cvxClaimsPrincipal];
                        }
                        console.log("Claims principal is null in local storage... restoring.");
                        return [4 /*yield*/, this.populateAndSaveCvxClaimsPrincipalProperties(account)];
                    case 1:
                        _a.sent();
                        console.log("Successfully retrieved CvxClaimsPrincipal with name: ".concat(this.cvxClaimsPrincipal.name));
                        return [2 /*return*/, this._cvxClaimsPrincipal];
                }
            });
        });
    };
    CalClient.prototype.populateAndSaveCvxClaimsPrincipalProperties = function (account) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, cacheStorage, error_4;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 3, , 4]);
                        _a = this;
                        _b = CvxClaimsPrincipal.bind;
                        _c = [{}];
                        return [4 /*yield*/, this.setUserAgentPropertiesToCvxClaimsPrincipal(account)];
                    case 1:
                        _d = [__assign.apply(void 0, _c.concat([_e.sent()]))];
                        return [4 /*yield*/, this.setGraphPropertiesToCvxClaimsPrincipal()];
                    case 2:
                        _a._cvxClaimsPrincipal = new (_b.apply(CvxClaimsPrincipal, [void 0, __assign.apply(void 0, _d.concat([_e.sent()]))]))();
                        cacheStorage = this.getCacheStorage();
                        cacheStorage.setItem(Constants.LOCAL_STORAGE_CVX_CLAIMS_PRINCIPAL_KEY, JSON.stringify(this._cvxClaimsPrincipal));
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _e.sent();
                        throw ("Could not store cvxClaimsPrincipal in local storage: " + error_4);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CalClient.prototype.setUserAgentPropertiesToCvxClaimsPrincipal = function (account) {
        return __awaiter(this, void 0, void 0, function () {
            var msalAccount;
            return __generator(this, function (_a) {
                msalAccount = account.localAccountId;
                if (msalAccount == null) {
                    throw ("Could not retrieve account properties from MSAL user agent.");
                }
                return [2 /*return*/, {
                        objectId: account.localAccountId,
                        userName: account.username,
                        name: account.name,
                        audience: account.idTokenClaims["aud"],
                        roles: account.idTokenClaims["roles"],
                        issuer: account.idTokenClaims["iss"],
                        tenantId: account.tenantId,
                        tokenVersion: account.idTokenClaims["ver"]
                    }];
            });
        });
    };
    CalClient.prototype.setGraphPropertiesToCvxClaimsPrincipal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var aadToken, graphInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAADToken()];
                    case 1:
                        aadToken = _a.sent();
                        if (!(aadToken == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getAADToken()];
                    case 2:
                        aadToken = (_a.sent());
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this._msGraphClient.getUserProperties(aadToken, null)];
                    case 4:
                        graphInfo = _a.sent();
                        return [2 /*return*/, {
                                email: graphInfo.mail,
                                phoneNumber: graphInfo.mobilePhone,
                                logOnName: graphInfo.onPremisesSamAccountName,
                                office: graphInfo.officeLocation,
                                jobTitle: graphInfo.jobTitle,
                                userType: graphInfo.userType,
                                isUser: true,
                                personRelationshipType: graphInfo[Constants.MS_GRAPH_ATTRIBUTES_MAP.get("employeeType")],
                                provisioningId: graphInfo[Constants.MS_GRAPH_ATTRIBUTES_MAP.get("provisioningId")]
                            }];
                }
            });
        });
    };
    CalClient.prototype.shouldPopupForLogin = function () {
        return this._config.cacheLocation == BrowserCacheLocation.MemoryStorage || this._config.popupForLogin;
    };
    CalClient.prototype.signInUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loginRequest, _a, error_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        loginRequest = {
                            scopes: this._config.oidcScopes
                        };
                        if (!this.shouldPopupForLogin()) {
                            this._clientApplication.loginRedirect(loginRequest);
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, this._clientApplication.loginPopup(loginRequest)];
                    case 2:
                        _a._account = (_b.sent()).account;
                        return [3 /*break*/, 4];
                    case 3:
                        error_5 = _b.sent();
                        console.log("There was an error logging into Azure AD. ".concat(error_5));
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CalClient.prototype.getCacheStorage = function () {
        var cacheStorage;
        switch (this._config.cacheLocation) {
            case BrowserCacheLocation.LocalStorage:
            case BrowserCacheLocation.MemoryStorage:
                cacheStorage = localStorage;
                break;
            case BrowserCacheLocation.SessionStorage:
                cacheStorage = sessionStorage;
                break;
            default:
                throw "".concat(this._config.cacheLocation, " is not a recognized cache storage location");
        }
        return cacheStorage;
    };
    CalClient.prototype.validateConfig = function () {
        var config = this._config;
        if (!Object.keys(BrowserCacheLocation)
            .map(function (key) { return BrowserCacheLocation[key]; })
            .includes(config.cacheLocation)) {
            throw "".concat(config.cacheLocation, " is not a recognized cache storage location");
        }
        if (config.autoSignIn && config.cacheLocation == BrowserCacheLocation.MemoryStorage) {
            // currently auto login isn't supported with memoryStorage
            throw "autoSignIn was specified and cacheLocation is memoryStorage... this is currently not supported.\nIf you want to keep autoSignIn on, change the cacheLocation to either sessionStorage or localStorage";
        }
    };
    return CalClient;
}());
export { CalClient };
